export default {
    environment: 'staging',
    siteRoot: 'https://tipple.xyz',
    authenticationCookie: 'tipple-token',
    existingAuthenticationCookie: 'tpl_session',
    cookieDomain: '.tipple.xyz',
    confirmedAddressCookie: 'tipple-address',
    deviceIdentifierCookie: 'tipple-device-identifier',
    userIdentifierCookie: 'tipple-user-identifier',
    ageVerificationCookie: 'tipple-age-verification',
    imageBase: "https://content.tipple.xyz/tipple",
    authenticationURI: '/api',
    baseURI: '/api',
    googleApiKey: 'AIzaSyB593DyivCmoxuZWhFaYv6I2xroE0VsoCk',
    splitIoAuthorizationKey: 'j6qddmm24op529cgi49h76209nanld0ma3i9',
    splitIoTrafficType: 'user',
    nrLicenseKey: "5849d0b811",
    nrApplicationID: "233991980",
    nrReleaseName: '@@NR_RELEASE_NAME@@',
    nrReleaseId: '@@NR_RELEASE_ID@@',
    cacheBust: 'cc1930d8d162aa481ec0d99ae8d0aec9070b29ed',
    segmentKey: "SnAbdJoOW1pfbexM70tTuYgT11jKHfsD",
    liveChatLicense: "7330911",
    enableAnalytics: true,
    defaultStorePath: "/bottleshop/victoria/melbourne",
    defaultStoreId: 34,
    siteId: 1,
    theme: 'tipple.xyz',
    mapboxKey: 'pk.eyJ1IjoidGlwcGxlIiwiYSI6ImNrMG9rMmRhbzBibmkzbWp3eW9scWM1YzMifQ.xvPBJYdLD1XAQFpk96o0Kw',
    idVerificationRedirectURI: 'https://tipple.xyz/verify/identification'
}